import $ from 'jquery';

$(function () {
	if ($('.js-accor1-btn').length) {
		$('.js-accor1-btn').on('click', function () {
			const content = $(this).parents('.js-accor1').find('.js-accor1-content')

			$(this).toggleClass('is-active')
			content.slideToggle()
		})
	}
})

$(function () {
	function handleAccor1() {
		if ($('.js-accor2').length) {

			$('.js-accor2').each(function () {
				const _thisAccor = $(this)
				const items = _thisAccor.find('.js-accor2-item')
				const content = _thisAccor.find('.js-accor2-content')
				const btn = _thisAccor.find('.js-accor2-btn')
				const btnText = _thisAccor.find('.js-accor2-btn span')

				let hContent = content.innerHeight()

				if (items.length > 3) {
					let count = 0
					let maxh = 0

					btn.addClass('is-active')

					items.each(function () {
						if (count < 3) {
							maxh += items.innerHeight()
							count++
						}
					})

					content.css('max-height', maxh)

					btnText.on('click', function () {
						if (btn.hasClass('is-open')) {
							content.css('max-height', maxh)
							btn.removeClass('is-open')
						} else {
							content.css('max-height', hContent)
							btn.addClass('is-open')
						}
					})

				} else {
					btn.removeClass('is-active is-open')
					content.removeAttr('style')
				}
			})
		}
	}

	if ($(window).outerWidth() < 768) {
		handleAccor1()
	}

	$(window).on('resize', () => {
		if ($(window).outerWidth() < 768) {
			setTimeout(() => {
				handleAccor1()
			}, 400);

		} else {
			setTimeout(() => {
				$('.js-accor2-content').removeAttr('style')
				$('.js-accor2-btn').removeClass('is-active is-open')
			}, 400);
		}
	})
})
