import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

$(function () {
	const slide1 = $('.js-slide1')

	if (slide1.length) {
		let slide1Ele = []
		let timeout

		function initSlider() {
			let count = 0

			slide1.each(function () {
				const _thisSlide = $(this)

				let optionsSlide = {
					loop: true,
					autoHeight: true,
					observer: true,
					pagination: {
						el: ".swiper-pagination",
						clickable: true,
					}
				}

				if ($(window).outerWidth() > 767) {
					optionsSlide = {
						...optionsSlide,
						centeredSlides: true,
						slidesPerView: 2,
					}

				} else {
					optionsSlide = {
						...optionsSlide,
						effect: "fade",
					}
				}

				slide1Ele[count] = new Swiper(_thisSlide[0], optionsSlide);

				count++
			})
		}

		initSlider()

		$(window).on('resize', function () {
			if (timeout) {
				clearTimeout(timeout)
			}

			timeout = setTimeout(() => {
				let i = 0

				slide1.each(() => {
					slide1Ele[i].destroy()
					i++
				})

				initSlider()
			}, 1000);
		})
	}
})
