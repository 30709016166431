import $ from 'jquery';

function iOs() {
	return [
		'iPad Simulator',
		'iPad',
	].includes(navigator.platform)
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


if (iOs()) {
	if ($(window).outerWidth() < 1025 && $(window).outerWidth() > 767) {
		$('body').addClass('is-ipad')
	}
}
