import $ from 'jquery';

$(function () {
	if ($('.js-tab1-btn').length) {
		$('.js-tab1-btn').on('click', function () {
			const parent = $(this).parents('.js-tab1')
			const content = parent.find('.js-tab1-content')
			const data = $(this).find('[data-tab-btn]').attr('data-tab-btn')

			content.removeClass('is-active')
			parent.find(`[data-tab-content=${data}]`).addClass('is-active')
		})
	}
})
