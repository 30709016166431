import $ from 'jquery';
import 'jquery-match-height';

$(function () {
	function handleMatchHeight() {
		if ($(window).outerWidth() > 767) {
			for (let i = 1; i < 10; i++) {
				if ($(`.js-matchHeight${i}`).length) {
					$(`.js-matchHeight${i}`).matchHeight();
				}
			}

		} else {
			for (let i = 1; i < 10; i++) {
				if ($(`.js-matchHeight${i}`).length) {
					$(`.js-matchHeight${i}`).removeAttr('style');
				}
			}
		}
	}

	handleMatchHeight()

	$(window).on('resize', () => handleMatchHeight())
})
